import React from 'react';
import { Star, DollarSign, ChevronUp, ChevronDown, ExternalLink, CheckCircle, MapPin, User } from 'lucide-react';
import { Card, CardHeader, CardContent } from "./components/ui/card"
import { Button } from "./components/ui/button"

const FoodItem = ({ item, expandedItem, toggleExpand, getCategoryIcon }) => {
  const getPriceRangeDisplay = (priceRange) => {
    switch (priceRange) {
      case 'B40':
        return (
          <span className="flex items-center">
            <DollarSign className="w-4 h-4 text-green-500" />
            <span className="text-sm font-medium ml-1">B40</span>
          </span>
        );
      case 'M40':
        return (
          <span className="flex items-center">
            <DollarSign className="w-4 h-4 text-green-500" />
            <DollarSign className="w-4 h-4 text-green-500" />
            <span className="text-sm font-medium ml-1">M40</span>
          </span>
        );
      default:
        return null;
    }
  };

  return (
    <Card>
      <CardHeader className="pb-2">
        <div className="flex justify-between items-start">
          <div className="flex">
            <img src={item.image} alt={item.name} className="w-24 h-24 object-cover rounded-lg mr-4" />
            <div>
              <h3 className="text-lg font-semibold mb-2">{item.name}</h3>
              <div className="flex items-center space-x-4 mb-2">
                <span className="flex items-center text-gray-500">
                  {getCategoryIcon(item.category)}
                  <span className="ml-1">{item.category}</span>
                </span>
              </div>
              <div className="flex items-center space-x-4">
                {getPriceRangeDisplay(item.priceRange)}
                <span className="flex items-center">
                  <Star className="text-yellow-400 w-4 h-4 mr-1" />
                  <span>{item.rating}</span>
                </span>
                {item.verified && (
                  <CheckCircle className="w-4 h-4 text-blue-400" title="Verified" />
                )}
              </div>
              {item.suggestedBy && (
                <div className="flex items-center text-sm text-gray-500 mt-1">
                  <User className="w-4 h-4 mr-1" />
                  <span>Suggested by: {item.suggestedBy}</span>
                </div>
              )}
              {item.verified && item.verifiedBy && (
                <div className="flex items-center text-sm text-blue-500 mt-1">
                  <CheckCircle className="w-4 h-4 mr-1" />
                  <span>Verified by: {item.verifiedBy}</span>
                </div>
              )}
            </div>
          </div>
          <Button
            variant="ghost"
            size="icon"
            onClick={() => toggleExpand(item.id)}
          >
            {expandedItem === item.id ? <ChevronUp /> : <ChevronDown />}
          </Button>
        </div>
      </CardHeader>
      {expandedItem === item.id && (
        <CardContent>
          <p className="text-gray-600 mb-2">{item.description}</p>
          <p className="text-gray-500 text-sm mb-2 flex items-center">
            <MapPin className="w-4 h-4 mr-1" />
            {item.location}
          </p>
          <div className="flex justify-end items-center">
            <Button
              variant="link"
              asChild
              className="text-blue-600 hover:text-blue-800"
            >
              <a href={item.googleReviewsLink} target="_blank" rel="noopener noreferrer" className="flex items-center">
                <ExternalLink className="w-4 h-4 mr-1" />
                Google Reviews
              </a>
            </Button>
          </div>
        </CardContent>
      )}
    </Card>
  );
};

export default FoodItem;