import React from 'react';
import { FoodProvider } from './FoodContext';
import GastronautsFoodieApp from './GastronautsFoodieApp';

const App = () => {
  return (
    <FoodProvider>
      <GastronautsFoodieApp />
    </FoodProvider>
  );
};

export default App;