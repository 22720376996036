// foodData.js
export const initialFoodItems = [{
    "id": 1,
    "name": "Abang Mike's Nasi Padang",
    "category": "Gaass",
    "rating": 4.5,
    "likes": 1200,
    "priceRange": "B40",
    "image": "https://lh3.googleusercontent.com/p/AF1QipPP7PY0Tv4mNiQ3iKfVTOWLBpBxMxZFls8r8JCF=s0",
    "description": "Bussin Nasi Padang",
    "googleReviewsLink": "https://g.co/kgs/hsKC4mZ",
    "verified": true,
    "location": "Petaling Jaya, PJ"
  }, {
    "id": 2,
    "name": "Kedai Kopi Binjai",
    "category": "Gaass",
    "rating": 3.6,
    "likes": 980,
    "priceRange": "M40",
    "image": "https://lh5.googleusercontent.com/p/AF1QipOxNVIsbvZzHotrdXvARi1fTTg-F9Fa3Sx-05um=s0",
    "description": "Decent nasi campur",
    "googleReviewsLink": "https://maps.app.goo.gl/RT8NFF1vxX6zDe6e8",
    "verified": true,
    "location": "Kuala Lumpur, KL"
  }, {
    "id": 3,
    "name": "Jessie's ABC",
    "category": "Yumss",
    "rating": 4.1,
    "likes": 1100,
    "priceRange": "B40",
    "image": "https://lh5.googleusercontent.com/p/AF1QipOo3C2VzIQfjeshVBNK-yWNVn9NO0nybPJtVN3l=s0",
    "description": "Bussin shaved ice.",
    "googleReviewsLink": "https://g.co/kgs/SgFcWRn",
    "verified": true,
    "location": "Petaling Jaya, PJ"
  }, {
    "id": 4,
    "name": "Brella Bakehouse",
    "category": "Yumss",
    "rating": 4.4,
    "likes": 950,
    "priceRange": "M40",
    "image": "https://lh5.googleusercontent.com/p/AF1QipOWmdCFxAG_5dNZO-z2-tP-wrO7vip1pIl5O5Is=s0",
    "description": "Solid bakery house, good coffee.",
    "googleReviewsLink": "https://g.co/kgs/PH6qEz9",
    "verified": true,
    "location": "Petaling Jaya, PJ"
  }, {
    "name": "KAKIYUKI",
    "category": "Yumss",
    "description": "Bussin Bingsu",
    "priceRange": "M40",
    "googleReviewsLink": "https://g.co/kgs/ukWKrbc",
    "image": "https://lh5.googleusercontent.com/p/AF1QipPhAxwmwdPILtHGbNVnHU5RGV6TP4cujBGNlD4q=s0",
    "rating": 4.1,
    "likes": 0,
    "id": 5,
    "verified": true,
    "location": "Petaling Jaya, PJ"
  }, {
    "name": "Lubuk Bangku",
    "category": "Gaass",
    "description": "OK nasi campur",
    "priceRange": "B40",
    "googleReviewsLink": "https://maps.app.goo.gl/jxF4o4zkruPMN9yu9",
    "image": "https://lh5.googleusercontent.com/p/AF1QipPYm0k55cHcxUAZFC6k35ujNZTXs_JwcqXUxnDT=s0",
    "rating": 4.1,
    "likes": 0,
    "id": 6,
    "verified": false,
    "location": "Bangsar"
  }, {
    "name": "Lenggang Kangkong",
    "category": "Gaass",
    "description": "Bussin home cook food.",
    "priceRange": "B40",
    "googleReviewsLink": "https://maps.app.goo.gl/DaDz2kXCapK444rh7",
    "image": "https://lh5.googleusercontent.com/p/AF1QipOw6YY9S2jwSTpNy1hYZMejtMFWKdq7rNE4NHAt=s0",
    "rating": 4.8,
    "likes": 0,
    "id": 7,
    "verified": true,
    "location": "Petaling Jaya, PJ"
  }, {
    "name": "Subang Ria",
    "category": "Gaass",
    "description": "Tricky food court",
    "priceRange": "M40",
    "googleReviewsLink": "https://maps.app.goo.gl/LoUNCWUqJXCqfATG7",
    "image": "https://lh5.googleusercontent.com/p/AF1QipMep5JviaXzjfrGesDASMAgTm4tc1lmdNCwltCX=s0",
    "rating": 4.1,
    "likes": 0,
    "id": 8,
    "verified": true,
    "location": "Petaling Jaya, PJ"
  }, {
    "name": "Bilal Nasi Kandar",
    "category": "Gaass",
    "description": "Sandar nasi kandar",
    "priceRange": "B40",
    "googleReviewsLink": "https://g.co/kgs/LmtWaiC",
    "image": "https://lh5.googleusercontent.com/p/AF1QipOMaAuzOtvqHCmTmnqyTYmXPfuxGkbn7C7GMowG=s0",
    "rating": 4.5,
    "likes": 0,
    "id": 9,
    "verified": true,
    "location": "Petaling Jaya, PJ"
  },
  {
    "name": "Shahir FF Burger",
    "category": "Gaass",
    "description": "Sloppy street burger.",
    "priceRange": "B40",
    "googleReviewsLink": "https://g.co/kgs/mzT9gFj",
    "image": "https://lh3.googleusercontent.com/p/AF1QipNHtwidukjlhY5dazGawIv-e6iF8osrT1Sfv_Bd=s0",
    "rating": 4.4,
    "likes": 0,
    "id": 10,
    "verified": true,
    "location": "Petaling Jaya, PJ"
  },
  {
    "name": "Pizza Mansion",
    "category": "Gaass",
    "description": "Vibes pizza eatery",
    "priceRange": "M40",
    "googleReviewsLink": "https://g.co/kgs/ytHxaTt",
    "image": "https://lh5.googleusercontent.com/p/AF1QipMZFoE8MYyNg-1TmBuV_5Bfn_kYio6PZ_bZwxqb=s0",
    "rating": 4.3,
    "likes": 0,
    "id": 11,
    "verified": true,
    "location": "Petaling Jaya, PJ"
  },
  {
    "name": "Tingkap",
    "category": "Gaass",
    "description": "OK breakfast cafe.",
    "priceRange": "M40",
    "googleReviewsLink": "https://g.co/kgs/sHjTcFR",
    "image": "https://lh5.googleusercontent.com/p/AF1QipOlyGAFKLqb5VTXcNq-Lu2_vxVWLzCzl81gHRy4=s0",
    "rating": 4.2,
    "likes": 0,
    "id": 12,
    "verified": true,
    "location": "Petaling Jaya, PJ"
  },
  {
    "name": "Kueh Subang",
    "category": "Gaass",
    "description": "Decent breakfast cafe.",
    "priceRange": "M40",
    "googleReviewsLink": "https://g.co/kgs/8J5kP3q",
    "image": "https://lh5.googleusercontent.com/p/AF1QipM7sw7uKfR1oUmEZ47-xPfxpHia6fBvoU2LBowb=s0",
    "rating": 4.0,
    "likes": 0,
    "id": 13,
    "suggestedBy": "Baq",
    "verified": true,
    "verifiedBy": "AP, Danish",
    "location": "Subang Jaya"
  },
  {
    "name": "Yodmilk",
    "category": "Yumss",
    "description": "Bagel, coffee, ice cream",
    "priceRange": "M40",
    "googleReviewsLink": "https://g.co/kgs/4rEwWNu",
    "image": "https://lh5.googleusercontent.com/p/AF1QipPUo42cz7tXuLYv4LThnVZcmKx230EcQ73BYAxN=w141-h235-n-k-no-nu",
    "rating": 4.7,
    "id": 14,
    "suggestedBy": "Baq",
    "verified": false,
    "verifiedBy": "None",
    "location": "Subang Jaya"
  },
  {
    "name": "Kedai Makan Najwa Saw",
    "category": "Gaass",
    "description": "Chinese cook Kepong style",
    "priceRange": "B40",
    "googleReviewsLink": "https://maps.app.goo.gl/ZvWLVDqp7zRc8u5M7",
    "image": "https://lh5.googleusercontent.com/p/AF1QipPwOTeBXopq69XsfasQSSgOM83FQNOZ6rnAPque=s0",
    "rating": 4.9,
    "id": 15,
    "suggestedBy": "Gard",
    "verified": true,
    "verifiedBy": "Baq",
    "location": "Kepong, Kuala Lumpur, KL"
  },
  {
    "name": "Syed Maju Sky Awani",
    "category": "Gaass",
    "description": "Nasi goreng aYumss",
    "priceRange": "B40",
    "googleReviewsLink": "https://maps.app.goo.gl/kevJYMMrEQ897EkVA?g_st=ic",
    "image": "https://lh5.googleusercontent.com/p/AF1QipM0RdORp-23_9WvdFIPaiBytY83Ypv9YFcpRQk1=s0",
    "suggestedBy": "Takiya Genji",
    "rating": 3.5,
    "id": 16,
    "verified": false,
    "verifiedBy": "Baq",
    "location": "Kuala Lumpur, KL"
  },
  {
    "name": "Sri Pantai Seafood",
    "category": "Gaass",
    "description": "Ikan bakar padu + view laut tenang",
    "priceRange": "M40",
    "googleReviewsLink": "https://g.co/kgs/4Uq7WbQ",
    "image": "https://lh3.googleusercontent.com/p/AF1QipPOXKwkvL2Awk9X1HddTjPKCxUNeXE2KLG6KVFz=s1360-w1360-h1020",
    "suggestedBy": "Jip",
    "rating": 4.1,
    "id": 17,
    "verified": false,
    "verifiedBy": "Baq",
    "location": "Pasir Gudang, Johor"
  },
  {
    "name": "Universal Sambal",
    "category": "Gaass",
    "description": "Siakap goreng memang steam, cendawan goreng memang enoki",
    "priceRange": "M40",
    "googleReviewsLink": "https://g.co/kgs/px3bsmH",
    "image": "https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEjEAcwOIfOv2hfMeARL24vklvjHi4Nn6yOL7c6xrVGME8HOkqrWJt2qstHwcKE_oRDOIJl52tU7ZGftepjFyV5tGa6JlHCop_dqXwJWTr9Is-1hD2ijjRz9J8obeUZvBgMUh8fx1g4Hsvy7cQk1MUKs-pnfuPbHN5SlOXkXicQnN02b9hgRkuheXZVL/w640-h480/IMG20220624154914.jpg",
    "suggestedBy": "Jip",
    "rating": 4.2,
    "id": 18,
    "verified": false,
    "verifiedBy": "Baq",
    "location": "Taman Universiti, Johor Bahru, JB"
  },
  {
    "name": "Nasi Kandar Marwah",
    "category": "Gaass",
    "description": "Best ",
    "priceRange": "M40",
    "googleReviewsLink": "https://g.co/kgs/f1NpyNS",
    "image": "https://lh5.googleusercontent.com/p/AF1QipP1namMGFeq2pVCvYTUurIstHHXP29akDSko-N1=s0",
    "suggestedBy": "Jip",
    "rating": 4.7,
    "id": 19,
    "verified": false,
    "verifiedBy": "Baq",
    "location": "Taman Mount Austin, Johor Bahru, JB"
  },
  {
    "name": "Coconut Shake Alai",
    "category": "Gaass",
    "description": "Underrated coconut shake \nMee goreng melayu paduS \n",
    "priceRange": "B40",
    "googleReviewsLink": "https://g.co/kgs/Ls5X3QF",
    "image": "https://lh3.googleusercontent.com/p/AF1QipMapzLLeqRuC72_7d_IeGFU2X2duoVE9p1msA=s0",
    "suggestedBy": "Jip",
    "rating": 4.2,
    "id": 20,
    "verified": false,
    "verifiedBy": "Baq",
    "location": "Melaka"
  },
  {
    "name": "Restoran Lot 85",
    "category": "Gaass",
    "description": "Nasi campur best 4 lunch \nasam pedas ðŸ˜ masak lemak ðŸ˜",
    "priceRange": "M40",
    "googleReviewsLink": "https://g.co/kgs/bdLuovW",
    "image": "https://lh3.googleusercontent.com/p/AF1QipPTgN_neeeTicJ2S9xAyfsR29VXRHvq0jxCHoEd=s1360-w1360-h1020",
    "suggestedBy": "Jip",
    "rating": 4.0,
    "id": 21,
    "verified": false,
    "verifiedBy": "Baq",
    "location": "Kampung Durian Daun Dalam, Melaka"
  }
  
  ];
  
  export const categories = ['Gaass', 'Yumss', 'IDK'];
  