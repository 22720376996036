import React from 'react';
import { Rocket, Dessert, Annoyed } from 'lucide-react';
import { Button } from "./components/ui/button"

const CategoryList = ({ categories, selectedCategory, handleCategoryClick }) => {
  const getCategoryIcon = (category) => {
    switch (category) {
      case 'Gaass':
        return <Rocket className="w-4 h-4 mr-1" />;
      case 'Yumss':
        return <Dessert className="w-4 h-4 mr-1" />;
      case 'IDK':
        return <Annoyed className="w-4 h-4 mr-1" />;
      default:
        return null;
    }
  };

  return (
    <div className="flex flex-nowrap overflow-x-auto sm:flex-wrap sm:overflow-x-visible space-x-2 mb-6 pb-2 sm:pb-0">
      {categories.map((category) => (
        <Button
          key={category}
          variant={selectedCategory === category ? "default" : "outline"}
          size="sm"
          onClick={() => handleCategoryClick(category)}
          className={`flex-shrink-0 sm:flex-shrink ${
            selectedCategory === category && category === 'IDK' 
              ? 'bg-red-500 hover:bg-red-600' 
              : selectedCategory === category
              ? 'bg-yellow-400 hover:bg-yellow-500'
              : ''
          }`}
        >
          {getCategoryIcon(category)}
          <span className="text-sm font-medium">{category}</span>
        </Button>
      ))}
    </div>
  );
};

export default CategoryList;