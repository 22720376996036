import React, { useState, useEffect, useRef } from 'react';
import { Rocket, Dessert, Annoyed, SortDesc, Users, Star, MapPin } from 'lucide-react';
import { useFoodContext } from './FoodContext';
import Header from './Header';
import CategoryList from './CategoryList';
import FoodItem from './FoodItem';
import AddPlaceForm from './AddPlaceForm';
import { Button } from "./components/ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./components/ui/select";
import { Card, CardContent } from "./components/ui/card";
import { Badge } from "./components/ui/badge";

const ITEMS_PER_PAGE = 5;

const GastronautsFoodieApp = () => {
    const { foodItems } = useFoodContext();
    const [selectedCategory, setSelectedCategory] = useState('Gaass');
    const [searchTerm, setSearchTerm] = useState('');
    const [expandedItem, setExpandedItem] = useState(null);
    const [isSearchExpanded, setIsSearchExpanded] = useState(false);
    const [isAddFormVisible, setIsAddFormVisible] = useState(false);
    const [location, setLocation] = useState('');
    const [randomItems, setRandomItems] = useState([]);
    const [sortBy, setSortBy] = useState('none');
    const [isSearchingAllLocations, setIsSearchingAllLocations] = useState(false);
    const [visibleItems, setVisibleItems] = useState(ITEMS_PER_PAGE);
    const sortRef = useRef(null);

    const toggleAddPlaceForm = () => {
        setIsAddFormVisible(!isAddFormVisible);
    };

    const categories = ['Gaass', 'Yumss', 'IDK'];

    const friends = [...new Set(foodItems.map(item => item.suggestedBy).filter(Boolean))];

    const sortOptions = [
        { value: 'none', label: 'Default', icon: <SortDesc className="w-4 h-4" /> },
        { value: 'rating', label: 'Rating', icon: <Star className="w-4 h-4" /> },
        ...friends.map(friend => ({ value: friend, label: friend, icon: <Users className="w-4 h-4" /> }))
    ];

    const fuzzyLocationMatch = (itemLocation, userLocation) => {
        if (!userLocation || isSearchingAllLocations) return true;
        const itemWords = itemLocation.toLowerCase().split(/\s+/);
        const userWords = userLocation.toLowerCase().split(/\s+/);
        return userWords.some(word => itemWords.some(itemWord => itemWord.includes(word)));
    };

    useEffect(() => {
        if (selectedCategory === 'IDK' && !searchTerm) {
            const gaassItems = foodItems.filter(item => item.category === 'Gaass' && fuzzyLocationMatch(item.location, location));
            const yumssItems = foodItems.filter(item => item.category === 'Yumss' && fuzzyLocationMatch(item.location, location));

            const randomGaass = gaassItems[Math.floor(Math.random() * gaassItems.length)];
            const randomYumss = yumssItems[Math.floor(Math.random() * yumssItems.length)];

            setRandomItems([randomGaass, randomYumss].filter(Boolean));
        } else {
            setRandomItems([]);
        }
        setVisibleItems(ITEMS_PER_PAGE);
    }, [selectedCategory, searchTerm, foodItems, location, isSearchingAllLocations]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (sortRef.current && !sortRef.current.contains(event.target)) {
                setIsSearchExpanded(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const filteredItems = foodItems.filter(item =>
        (selectedCategory === 'All' || selectedCategory === 'IDK' || item.category === selectedCategory) &&
        (item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.location.toLowerCase().includes(searchTerm.toLowerCase())) &&
        fuzzyLocationMatch(item.location, location)
    );

    const sortedItems = (() => {
        switch (sortBy) {
            case 'rating':
                return [...filteredItems].sort((a, b) => b.rating - a.rating);
            case 'none':
                return filteredItems;
            default:
                return filteredItems.filter(item => item.suggestedBy === sortBy);
        }
    })();

    const displayedItems = selectedCategory === 'IDK' && !searchTerm
        ? randomItems
        : sortedItems.slice(0, visibleItems);

    const toggleExpand = (id) => {
        setExpandedItem(expandedItem === id ? null : id);
    };

    const handleSearch = (e) => {
        e.preventDefault();
        const newSearchTerm = e.target.search.value;
        setSearchTerm(newSearchTerm);
        setIsSearchExpanded(false);
        if (newSearchTerm) {
            setSelectedCategory('All');
        }
        setVisibleItems(ITEMS_PER_PAGE);
    };

    const getCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    fetch(`https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`)
                        .then(response => response.json())
                        .then(data => {
                            const city = data.city || data.locality || '';
                            setLocation(city);
                            setIsSearchingAllLocations(false);
                        })
                        .catch(error => {
                            console.error("Error getting location:", error);
                            setLocation("");
                        });
                },
                (error) => {
                    console.error("Error getting location:", error);
                    setLocation("");
                }
            );
        } else {
            setLocation("");
        }
    };

    const toggleLocationSearch = () => {
        setIsSearchingAllLocations(!isSearchingAllLocations);
        if (!isSearchingAllLocations) {
            setLocation("");
        } else {
            getCurrentLocation();
        }
    };

    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
        setSearchTerm('');
        setVisibleItems(ITEMS_PER_PAGE);
    };

    const getCategoryIcon = (category) => {
        switch (category) {
            case 'Gaass':
                return <Rocket className="w-4 h-4 mr-1" />;
            case 'Yumss':
                return <Dessert className="w-4 h-4 mr-1" />;
            case 'IDK':
                return <Annoyed className="w-4 h-4 mr-1" />;
            default:
                return null;
        }
    };

    const handleSortChange = (value) => {
        setSortBy(value);
        setVisibleItems(ITEMS_PER_PAGE);
    };

    const loadMore = () => {
        setVisibleItems(prevVisibleItems => prevVisibleItems + ITEMS_PER_PAGE);
    };

    return (
        <div className="w-full max-w-sm sm:max-w-md md:max-w-lg lg:max-w-2xl xl:max-w-3xl mx-auto bg-gray-100 p-4 sm:p-6 rounded-3xl shadow-lg">
            <Header
                toggleSearch={setIsSearchExpanded}
                setIsAddFormVisible={setIsAddFormVisible}
                isSearchExpanded={isSearchExpanded}
                handleSearch={handleSearch}
            />
            <Card className="mb-6">
                <CardContent className="pt-6">
                    <h2 className="text-3xl font-bold mb-1">Discover</h2>
                    <div className="flex items-center text-gray-500">
                        <Button variant="ghost" className="p-0" onClick={toggleLocationSearch}>
                            <MapPin className={`w-4 h-4 mr-1 ${isSearchingAllLocations ? 'text-blue-500' : 'text-red-500'}`} />
                            <span>{isSearchingAllLocations ? "All Locations" : (location || "Slatan City")}</span>
                        </Button>
                        <Badge variant="secondary" className="ml-2">
                            {sortedItems.length} shops
                        </Badge>
                    </div>
                    <p className="text-xs text-gray-400 mt-1">Last updated on July 2024</p>
                </CardContent>
            </Card>
            <div className="space-y-4 mb-6">
                <CategoryList
                    categories={categories}
                    selectedCategory={selectedCategory}
                    handleCategoryClick={handleCategoryClick}
                />
                <Select onValueChange={handleSortChange} value={sortBy}>
                    <SelectTrigger className="w-full">
                        <SelectValue placeholder="Sort by" />
                    </SelectTrigger>
                    <SelectContent>
                        {sortOptions.map((option) => (
                            <SelectItem key={option.value} value={option.value}>
                                <div className="flex items-center">
                                    {option.icon}
                                    <span className="ml-2">{option.label}</span>
                                </div>
                            </SelectItem>
                        ))}
                    </SelectContent>
                </Select>
            </div>
            <div className="flex flex-col space-y-4 mb-4">
                {displayedItems.length > 0 ? (
                    displayedItems.map((item) => (
                        <FoodItem
                            key={item.id}
                            item={item}
                            expandedItem={expandedItem}
                            toggleExpand={toggleExpand}
                            getCategoryIcon={getCategoryIcon}
                        />
                    ))
                ) : (
                    <Card>
                        <CardContent className="text-center py-6">
                            <p className="text-gray-600">
                                {sortBy !== 'none' && friends.includes(sortBy)
                                    ? `No items suggested by ${sortBy}${location && !isSearchingAllLocations ? ` in ${location}` : ''} yet. 😮‍💨`
                                    : `No items available${location && !isSearchingAllLocations ? ` in ${location}` : ''}. Maybe they're still gatekept 😮‍💨`}
                            </p>
                        </CardContent>
                    </Card>
                )}
            </div>
            {selectedCategory !== 'IDK' && visibleItems < sortedItems.length && (
                <div className="text-center mt-4">
                    <Button onClick={loadMore} variant="outline">
                        Load More
                    </Button>
                </div>
            )}
            <AddPlaceForm onClose={toggleAddPlaceForm} />
        </div>
    );
};

export default GastronautsFoodieApp;