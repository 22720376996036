import React, { createContext, useState, useContext } from 'react';
import { initialFoodItems } from './foodData';

const FoodContext = createContext();

export const FoodProvider = ({ children }) => {
    const [foodItems, setFoodItems] = useState(initialFoodItems);

    return (
        <FoodContext.Provider value={{ foodItems, setFoodItems }}>
            {children}
        </FoodContext.Provider>
    );
};

export const useFoodContext = () => useContext(FoodContext);