import React, { useState } from 'react';
import { Plus } from 'lucide-react';
import { useFoodContext } from './FoodContext';
import { Input } from "./components/ui/input"
import { Button } from "./components/ui/button"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./components/ui/select"
import { Textarea } from "./components/ui/textarea"
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "./components/ui/dialog"

const AddPlaceForm = ({ onClose }) => {
    const [name, setName] = useState('');
    const [category, setCategory] = useState('');
    const [description, setDescription] = useState('');
    const [priceRange, setPriceRange] = useState('');
    const [googleLink, setGoogleLink] = useState('');
    const [image, setImage] = useState('');
    const [suggestedBy, setSuggestedBy] = useState('');
    
    const { addFoodItem } = useFoodContext();
  
    const handleSubmit = (e) => {
      e.preventDefault();
      
      const newItem = {
        name,
        category,
        description,
        priceRange,
        googleReviewsLink: googleLink,
        image,
        rating: 0,
        likes: 0,
        suggestedBy,
      };

      addFoodItem(newItem);

      const formData = {
        name,
        category,
        description,
        priceRange,
        googleReviewsLink: googleLink,
        image,
        suggestedBy,
      };

      const jsonData = JSON.stringify(formData, null, 2);
      const subject = encodeURIComponent("New Food Place Suggestion");
      const body = encodeURIComponent(`New Food Place Suggestion:\n\n${jsonData}`);
      const mailtoLink = `mailto:admin@slatan.world?subject=${subject}&body=${body}`;

      window.location.href = mailtoLink;

      onClose();
    };
  
    return (
      <Dialog>
        <DialogTrigger asChild>
          <Button className="fixed bottom-6 right-6" size="icon">
            <Plus className="h-4 w-4" />
          </Button>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Add New Place</DialogTitle>
          </DialogHeader>
          <form onSubmit={handleSubmit}>
            <div className="space-y-4">
              <Input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Name"
                required
              />
              <Select onValueChange={setCategory} required>
                <SelectTrigger>
                  <SelectValue placeholder="Select a category" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="Gaass">Gaass</SelectItem>
                  <SelectItem value="Yumss">Yumss</SelectItem>
                </SelectContent>
              </Select>
              <Textarea
                id="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Description"
                required
              />
              <Select onValueChange={setPriceRange} required>
                <SelectTrigger>
                  <SelectValue placeholder="Select price range" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="B40">B40</SelectItem>
                  <SelectItem value="M40">M40</SelectItem>
                </SelectContent>
              </Select>
              <Input
                type="url"
                id="googleLink"
                value={googleLink}
                onChange={(e) => setGoogleLink(e.target.value)}
                placeholder="Google Reviews Link"
                required
              />
              <Input
                type="url"
                id="image"
                value={image}
                onChange={(e) => setImage(e.target.value)}
                placeholder="Image URL"
                required
              />
              <Input
                type="text"
                id="suggestedBy"
                value={suggestedBy}
                onChange={(e) => setSuggestedBy(e.target.value)}
                placeholder="Suggested By"
                required
              />
            </div>
            <div className="flex justify-end mt-4">
              <Button type="submit">Add Place</Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    );
  };

export default AddPlaceForm;