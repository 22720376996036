import React, { useState } from 'react';
import { Search } from 'lucide-react';
import { Input } from "./components/ui/input"
import { Button } from "./components/ui/button"

const Header = ({ handleSearch }) => {
  const [isSearchExpanded, setIsSearchExpanded] = useState(false);

  const toggleSearch = () => {
    setIsSearchExpanded(!isSearchExpanded);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSearch(e);
    setIsSearchExpanded(false);
  };

  return (
    <div className="flex flex-col mb-6">
      <div className="flex justify-between items-center mb-2">
        <div className="flex items-center">
          <img src="/logo192.png" alt="Gastronaut's Logo" className="w-8 h-8 mr-2" />
          <h1 className="text-2xl font-bold">
            <span className="text-blue-600">Gas</span>
            <span className="text-black">tronaut'S</span>
          </h1>
        </div>
        <Button 
          variant="outline" 
          size="icon"
          onClick={toggleSearch}
        >
          <Search className="h-4 w-4" />
        </Button>
      </div>
      {isSearchExpanded && (
        <div className="w-full mt-2">
          <form onSubmit={handleSubmit} className="relative">
            <Input 
              type="text" 
              name="search" 
              placeholder="Search" 
              className="pr-8"
            />
            <Button 
              type="submit" 
              size="icon"
              variant="ghost" 
              className="absolute right-2 top-1/2 transform -translate-y-1/2"
            >
              <Search className="h-4 w-4" />
            </Button>
          </form>
        </div>
      )}
    </div>
  );
};

export default Header;